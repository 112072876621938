export default {
  methods: {
    highlightText(text, filter) {
      text = text == null ? '' : String(text);
      filter = filter == null ? '' : String(filter);

      const position = text.toLowerCase().search(filter.toLowerCase());
      if (position > -1) {
        return `${text.substring(0, position)}<mark>${text.substring(position, position + filter.length)}</mark>${text.substring(position + filter.length)}`;
      }
      return text;
    }    
  }
}